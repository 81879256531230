import jobEn from "./job-board/en.json";
import jobZhHans from "./job-board/zh-hans.json";
import jobZhHant from "./job-board/zh-hant.json";
import commonEn from "./common/en.json";
import commonZhHans from "./common/zh-hans.json";
import commonZhHant from "./common/zh-hant.json";
import landingLmsEn from "./landing-lms/en.json";
import landingLmsZhHans from "./landing-lms/zh-hans.json";
import landingLmsZhHant from "./landing-lms/zh-hant.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  fallbackLocale: "en",
  messages: {
    en: {
      jobBoard: jobEn,
      common: commonEn,
      landingLms: landingLmsEn,
    },
    "zh-Hant": {
      jobBoard: jobZhHant,
      common: commonZhHant,
      landingLms: landingLmsZhHant,
    },
    "zh-Hans": {
      jobBoard: jobZhHans,
      common: commonZhHans,
      landingLms: landingLmsZhHans,
    },
  },
}));
